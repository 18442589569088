@import "~styles/var";
@import 'bootstrap-4-grid/scss/grid.scss';

.block {
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
}

.leftENV {
  .wAndT {
    .weather {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &.w_1 { background-image: url(~images/w_1.png); }
      &.w_2 { background-image: url(~images/w_2.png); }
      &.w_3 { background-image: url(~images/w_3.png); }
      &.w_4 { background-image: url(~images/w_4.png); }
    }
    .temperature {
      font-size: 35px;
      color: $pinkish-orange;
      &:after {
        content: '°C';
        color: $black;
        font-size: 14px;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #686868;
    margin-top: 20px;
    li {
      display: flex;
      margin-top: 7px;
      // span {
      //   &:first-of-type {
      //     flex: 3;
      //     font-weight: bold;
      //     color: $nice-blue;
      //     margin-right: 10px;
      //     white-space: nowrap;
      //     text-align-last: justify;
      //   }
      //   &:last-of-type {
      //     flex: 6;
      //   }
      // }
      div {
        flex: 3;
        &:first-of-type {
          color: $nice-blue;
          flex: 3;
        }
        &:last-of-type {
          position: relative;
          &:before {
            content: '';
            display: block;
            background: no-repeat;
            background-size: contain;
            background-position: center;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
          }
          &.aq0:before { background-image: url(./images/good.png); }
          &.aq1:before { background-image: url(./images/normal.png); }
          &.aq2:before { background-image: url(./images/bad.png); }
          &.sun:before {
            color: $pinkish-orange;
            content: attr(data-number);
            background-image: url(./images/sun@2x.png);
          }
          &.aq0:before,
          &.aq1:before,
          &.aq2:before {
            top: calc(50% - 5px);
          }
          &.sun:before {
            padding-top: 7px;
            text-align: center;
          }
          &.aq0:before,
          &.aq1:before,
          &.aq2:before {
            width: 30px;
            height: 35px;
          }
          &.sun:before {
            width: 38px;
            height: 38px;
          }
        }
      }
    }
    .img {
      width: 14px;
      height: 14px;
      display: inline-block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .ppl_0 { background-image: url('~images/ppl_0.png'); }
    .ppl_1 { background-image: url('~images/ppl_1.png'); }
    .ppl_2 { background-image: url('~images/ppl_2.png'); }
    .ppl_3 { background-image: url('~images/ppl_3.png'); }
    .ppl_4 { background-image: url('~images/ppl_4.png'); }
  }

  @include media-breakpoint-up(lg) {
    > .row {
      > *:first-of-type { padding-left: 0; }
      > *:last-of-type { padding-right: 0; }
    }
    bottom: 40px;
    right: 105px;
    .wAndT {
      display: flex;
      .weather {
        flex: 1;
        height: 55px;
      }
    }
    img {
      margin-right: 2px;
    }
    ul {
      font-size: 16px;
      li {
        white-space: nowrap;
      }
    }
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
}