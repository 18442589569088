@import "~styles/var";

.Pagination {
  padding: 0;
  li {
    margin: 0 10px;
    display: inline;
    a:hover, .active {
      color: $nice-blue;
      font-weight: bold;
    }
  }
}