@import "~styles/var";
@import 'bootstrap-4-grid/scss/grid.scss';

.Banner {
  z-index: 0;
  position: relative;
  background-repeat: no-repeat;
  background-position: center 120px;
  // 新增 Swipper-Banner
  // background-position: center 80px;
  &.isRandom {
    background-position-y: 0;
  }
  .bookEnvEdu {
    background-image: url('./images/booking@2x.png');
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .miniBoard {
    padding: 15px;
    position: absolute;
    background-color: #fff;
    .wAndT {
      .weather {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        &.w_1 { background-image: url(~images/w_1.png); }
        &.w_2 { background-image: url(~images/w_2.png); }
        &.w_3 { background-image: url(~images/w_3.png); }
        &.w_4 { background-image: url(~images/w_4.png); }
      }
      .temperature {
        font-size: 35px;
        color: $pinkish-orange;
        &:after {
          content: '°C';
          color: $black;
          font-size: 14px;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 14px;
      color: #686868;
      li {
        margin-top: 3px;
      }
      span {
        margin-left: 10px;
      }
      .img {
        width: 14px;
        height: 14px;
        display: inline-block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .ppl_0 { background-image: url('~images/ppl_0.png'); }
      .ppl_1 { background-image: url('~images/ppl_1.png'); }
      .ppl_2 { background-image: url('~images/ppl_2.png'); }
      .ppl_3 { background-image: url('~images/ppl_3.png'); }
      .ppl_4 { background-image: url('~images/ppl_4.png'); }
    }
  }
  marquee {
    font-size: 20px;
    color: #666;
    position: absolute;
    background-color: rgba(255,255,255,0.5);
  }
  @include media-breakpoint-up(lg) {
    top: 120px;
    height: calc(100vh - 120px);
    background-size: contain;
    margin-bottom: 120px;
    &.isRandom {
      height: calc(60vh - 120px);
      margin-bottom: 0px;
    }

    > .img {
      z-index: 1;
      position: absolute;
      &:hover:before {
        left: 100%;
        width: 210px;
        padding: 5px;
        position: absolute;
        background-color: #eee;
        content: attr(data-text);
      }
    }

    .center {
      width: calc((100vh - 120px) * .26);
      height: calc((100vh - 120px) * .16);
      left: calc(50% - 75vh + 90px);
      top: calc((100vh - 120px) * 0.61 + 120px);
      // background-color: red;
    }

    .iceStone {
      width: calc((100vh - 120px) * .10);
      height: calc((100vh - 120px) * .19);
      left: calc(50% - 85vh + 102px);
      top: calc((100vh - 120px) * 0.41 + 120px);
      // background-color: red;
    }

    .princess {
      width: calc((100vh - 120px) * .12);
      height: calc((100vh - 120px) * .15);
      left: calc(50% - 55vh + 110px);
      top: calc((100vh - 120px) * 0.61 + 80px);
      // background-color: red;
    }

    .queen {
      width: calc((100vh - 120px) * .15);
      height: calc((100vh - 120px) * .22);
      left: calc(50% - 20vh);
      top: calc((100vh - 350px) * 0.41 + 150px);
      // background-color: red;
    }

    .birdStone {
      width: calc((100vh - 120px) * .10);
      height: calc((100vh - 120px) * .15);
      left: calc(50% - 10vh + 100px);
      top: calc((100vh - 350px) * 0.41 + 150px);
      // background-color: red;
    }


    .fairyShoes {
      width: calc((100vh - 120px) * .12);
      height: calc((100vh - 120px) * .15);
      left: calc(50% - 10vh);
      top: calc((100vh - 120px) * 0.31 + 50px);
      // background-color: red;
    }

    .candleStone {
      width: calc((100vh - 120px) * .12);
      height: calc((100vh - 120px) * .18);
      left: calc(50% - 55vh + 60px);
      top: calc((100vh - 120px) * 0.61 - 30px);
      // background-color: red;
    }

    .beeStone {
      width: calc((100vh - 120px) * .12);
      height: calc((100vh - 120px) * .15);
      left: calc(50% + (100vh - 120px) * .53 - 10px);
      top: calc((100vh - 120px) * 0.21 + 150px);
      // background-color: red;
    }

    .blocker {
      height: 100%;
      position: relative;
      .bookEnvEdu {
        width: 106px;
        height: 138px;
  
        right: 350px;
        bottom: 40px;
      }
      .miniBoard {
        width: 250px;
        height: 150px;
        bottom: 40px;
        right: 105px;
        border-radius: 5px;
        .wAndT {
          display: flex;
          .weather {
            flex: 1;
            height: 55px;
          }
        }
        img {
          margin-right: 2px;
        }
        ul {
          font-size: 13px;
          li {
            white-space: nowrap;
          }
        }
      }
      marquee {
        right: 90px;
        width: 55%;
        bottom: 10px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    // top: 60px;
    width: 100vw;
    height: 400px;
    background-size: 820px;
    background-position: -80px 80px, center;
    // 新增 Swipper-Banner
    // background-position: -80px center;
    &.isRandom {
      background-size: cover;
      height: calc(60vh - 120px);
      background-position-x: center;
    }
    .bookEnvEdu {
      width: 119px;
      height: 156px;

      right: 20px;
      bottom: 10px;
    }
    .miniBoard {
      width: calc(100% - 30px);
      top: 400px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      display: flex;
      .wAndT {
        flex: 1;
        position: relative;
        .weather {
          height: 70px;
          background-size: contain;
          background-position: left center;
        }
        .temperature {
          right: 16px;
          bottom: -10px;
          position: absolute;
        }
      }
      ul {
        flex: 1;
        font-size: .9rem;
      }
    }
    marquee {
      left: 0;
      width: 100%;
    }
  }
}